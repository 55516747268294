import React from "react";
import OutlinePanel from "../../common/OutlinePanel";
import Button from "@prism/button";
import Row from "@prism/row";
import Col from "@prism/col";
import Form from "@prism/form";
import Input from "@prism/input";
import Message from "@prism/message";
import {ActionDropdown, ChargeableDropdown, DamageDropdown, SeverityDropdown} from "../damage/artComponents";
import TextCounter from "../../common/TextCounter";
import {addAutoDamage, addDamage} from "../../../actions/conditionActions";
import {connect} from "react-redux";
import {hasActiveImage} from "../../../actions/damageImageActions";
import {REQUIRED_PHOTO_ITEMS} from "../../../utils/requiredPhotoItems";
import TakePhoto from "../damage/capturePhoto/TakePhoto";
import {showExteriorFlatCarView} from "../../../actions/globalDisplayActions";
import csrf from "../../../csrf";
import Api from "../../Api";
import {APPROVALS_API_URL} from "../../../config/env";
import {DamagePricingInputs} from "../../../utils/DamagePricingInputs";
import SlidePanel from "../../SlidePanel";
import Pricing from "../Pricing";
import {getChromeStyleId, getSubSeries} from "../../../utils/utils";
import SpinnerOverlay from "../../Spinner/SpinnerOverlay";
import {CRSO, MISSING_ESTIMATE_MESSAGE, MISSING_PRICING_MESSAGE} from "../../../utils/constants";
import ItemDropdown from "../damage/artComponents/ItemDropdown";

const CRNE = "CRNE";

class DamageModalDetails extends React.Component {
    state = {
        validation: {
            damageIsValid: true,
            severityIsValid: true,
            chargeableIsValid: true
        },
        chargeable: null,
        chargeableDescription: null,
        item: this.props.damage.item,
        itemCode: this.props.damage.itemCode,
        subItemCode: this.props.damage.subItemCode,
        damage: this.props.damage.damage,
        showPricing: false,
        pricingTypes: [],
        damageCode: this.props.damage.damageCode,
        locationCode: this.props.damage.locationCode,
        severity: this.props.damage.severity,
        severityCode: this.props.damage.severityCode,
        action: this.props.damage.action ? this.props.damage.action : null,
        actionCode: this.props.damage.actionCode ? this.props.damage.actionCode : null,
        damageKey: this.props.damage.damageKey ? this.props.damage.damageKey : '',
        damageImageRequired: 'N',
        showPricingInputError: false,
        isApiLoading: false,
        notes: this.props.damage.notes
    };

    componentDidMount = async () => {
        await this.handleStartEstimate();
        this.validatePricingInputs();
    }

    calculateDamageKey = () => {
        return `L1-${this.state.itemCode}-${this.state.subItemCode}-${this.state.damageCode}`;
    };

    photoIsRequired = () => {
        if (!this.state.damageKey) {
            return false;
        }
        // CRSO
        if (this.props.condition.conditionType && (this.props.condition.conditionType.toUpperCase() !== CRNE)) {
            return (this.state.damageImageRequired === 'Y' ||
                    REQUIRED_PHOTO_ITEMS.includes(this.state.damageKey.split("-").slice(0, 4).join("-")
                )
            );
        }
        // CRNE
        return REQUIRED_PHOTO_ITEMS.includes(
            this.state.damageKey.split("-").slice(0, 4).join("-")
        );
    };

    setStatusMessage = (message) => {
        console.log("message", message)
    };

    handleItemChange = (item) => {
        this.setState({
            ...item,
            damageCode: "",
            severityCode: "",
            actionCode: "",
            chargeable: ""
        })
    };

    handleDamageChange = ({target: {options, selectedIndex, value}}) => {
        if (this.state.damageCode !== value) {
            this.setState({
                damageCode: value,
                damage: options[selectedIndex].text,
                statusMessage: {text: '', color: ''}
            }, () => this.handleSeverityChange({target: {value: '', options: [{text: ''}], selectedIndex: 0}}));
        }
    };

    handleSeverityChange = ({target: {options, selectedIndex, value}}) => {
        if (this.state.severityCode !== value) {
            this.setState({
                severityCode: value,
                severity: options[selectedIndex].text,
                statusMessage: {text: '', color: ''},
            }, () => this.handleActionChange({target: {value: '', options: [{text: ''}], selectedIndex: 0}}))
        }
    };

    handleActionChange = async ({target: {options, selectedIndex, value}}) => {
        if (this.state.actionCode !== value) {
            await this.setState({
                actionCode: value,
                action: options[selectedIndex].text,
                statusMessage: {text: '', color: ''}
            }, () => {
                this.setState({damageKey: this.calculateDamageKey()}, () => {
                    this.handleStartEstimate();
                })
            });
        }
    };

    handleChargeableChange = ({target: {options, selectedIndex, value}}) => {
        if (this.state.chargeable !== value) {
            this.setState({
                chargeable: value,
                chargeableDescription: options[selectedIndex].text
            })
        }
    };

    handleSaveDamage = () => {
        if (this.props.onSaveClick) {
            this.props.onSaveClick();
        }

        if(this.props.isStructural) {
            this.props.addDamage(this.compiledDamageObject());
        } else {
            this.props.addAutoDamage(this.compiledDamageObject());
        }
        this.props.toggle();
    };

    handleNotesChange = (event) => {
        this.setState({
            notes: event.value
        })
    };

    handleEditPartDescriptionChange = (event) => {
        this.setState({
            partDescription: event.value,
        });
    };

    compiledDamageObject = () => {
        return {
            item: this.state.item,
            itemCode: this.state.itemCode,
            subItemCode: this.state.subItemCode,
            damage: this.state.damage,
            damageCode: this.state.damageCode,
            severity: this.state.severity,
            severityCode: this.state.severityCode,
            action: this.state.action,
            actionCode: this.state.actionCode,
            locationCode: this.state.locationCode,
            chargeable: this.state.chargeable,
            chargeableDescription: this.state.chargeableDescription,
            notes: this.state.notes,
            damageKey: this.state.damageKey,
            repairLaborHours: parseFloat(this.state.repairLaborHours),
            repairLaborCost: parseFloat(this.state.repairLaborCost),
            paintLaborHours: parseFloat(this.state.paintLaborHours),
            paintLaborCost: parseFloat(this.state.paintLaborCost),
            partLaborHours: parseFloat(this.state.partLaborHours),
            partLaborCost: parseFloat(this.state.partLaborCost),
            partCost: parseFloat(this.state.partCost),
            finalPartCost: parseFloat(this.state.finalPartCost),
            pricePlanIds: this.state.pricePlanIds,
            partDescription: this.state.partDescription,
            hasImage: this.props.hasActiveImage(this.state.damageKey)
        }
    };

    handlePriceChange = async (event) => {
        await this.updateInputState(event)
        this.validatePricingInputs()
    };

    updateInputState = (event) => {
        let value = parseFloat(event?.target.value);
        if (isNaN(value)) {
            value = '';
        } else {
            value = Math.round(value * 100) / 100;
        }
        return new Promise((resolve) => {
            this.setState({[event.currentTarget.name]: value}, resolve)

        })
    }

    handleInputValidation = (dataFromChild) => {
        this.setState(dataFromChild);
    }

    formatCurrency = (event) => {
        if (isNaN(event.target.value) || event.target.value === '') {
            this.setState({
                [event.target.name]: '0.00'
            });
        } else {
            this.setState({
                [event.target.name]: parseFloat(event.target.value).toFixed(2)
            });
        }
    };

    handleStartEstimate = async () => {
        if (this.props.condition.conditionType && (this.props.condition.conditionType.toUpperCase() !== CRNE)) {
            let callPricing;
            try {
                callPricing = await this.handleShowPricing();
            } catch (e) {
                return;
            }

            if (callPricing) {
                await this.fetchPricing(this.damageRequestObject(`${this.state.itemCode}-${this.state.subItemCode}-${this.state.damageCode}`));
            }
        }
    }

    handleShowPricing = () => {
        this.setState({
            repairLaborHours: null,
            repairLaborCost: null,
            paintLaborHours: null,
            paintLaborCost: null,
            partLaborHours: null,
            partLaborCost: null,
            partCost: null,
            finalPartCost: null,
            pricePlanIds: null,
            isApiLoading: true
        });

        let headers = csrf.getCSRFHeaders();
        headers['Content-Type'] = 'application/json';
        let request = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({
                location: this.props.workOrderInfo.auctionCode,
                vin: this.props.unit?.vin,
                subSeries: getSubSeries(this.props.designatedDescription),
                chromeStyleId: getChromeStyleId(this.props.designatedDescription),
                groupCode: this.props.account.groupCode,
                accountNumber: this.props.consignment.manheimAccountNumber,
                categoryCode: this.props.consignment.categoryCode,
                consignmentHref: this.props.consignment.href,
                paintType: this.props.condition.paintType,
                damages: [this.state]
            })
        };

        return Api.fetchEstimate(request).then((data) => {
            const {model, mitchell, account} = data;
            const pricingTypes = [];
            let overlay = [];
            let showPricing = false;
            if (Array.isArray(account)) {
                if (account.length === 1) {
                    overlay.push('account');
                    this.updateDamageWithPricing('account', account);
                } else if (account.length > 1) {
                    let m = new Map();
                    m.set('Account', account);
                    pricingTypes.push(m)
                }
            }
            if (Array.isArray(model)) {
                if (model.length === 1) {
                    overlay.push('model');
                    this.updateDamageWithPricing('model', model);
                } else if (model.length > 1) {
                    let m = new Map();
                    m.set('Model', model);
                    pricingTypes.push(m)
                }
            }
            if (Array.isArray(mitchell)) {
                if (mitchell.length === 1) {
                    overlay.push('mitchell');
                    this.updateDamageWithPricing('mitchell', mitchell);
                } else if (mitchell.length > 1) {
                    let m = new Map();
                    m.set('Mitchell', mitchell);
                    pricingTypes.push(m)
                }
            }

            let callPricing;

            if (pricingTypes.length === 0 && overlay.length === 0) {
                this.resetDamagePricing();
                callPricing = false;
            }

            if (pricingTypes.length !== 0) {
                showPricing = true;
                callPricing = false;
            }

            this.setState({
                pricingTypes,
                showPricing: showPricing,
            });

            if (overlay.length !== 0 && pricingTypes.length === 0) {
                callPricing = true;
            }
            this.setState({isApiLoading: false});

            return callPricing;

        })
            .catch(e => {
                this.setState({isApiLoading: false});
                this.clearAndValidatePricingInputs();
                console.error("error fetching pricing:", e);
                throw new Error("Error fetching pricing");
            });
    };

    clearAndValidatePricingInputs = () =>  {
        this.resetDamagePricing();
        this.validatePricingInputs();
        this.setState({
            disabled: false,
            showEstimateError: true
        });
    }

    updateDamageWithPricing = (name, value) => {
        console.debug("updating pricing (popup)", {name: name, value: value})
        let pricePlanList = this.state.pricePlanId;
        const pricePlanId = {
            pricingType: name,
            pricePlanId: value[0].id
        };

        if (pricePlanList === undefined) {
            pricePlanList = [];
            pricePlanList.push(pricePlanId);
        } else {
            pricePlanList.push(pricePlanId);
        }

        this.setState({
            pricePlanIds: pricePlanList
        })
    };

    damageRequestObject = (damageKey) => {
        return {
            damageKey,
            item: this.state.item,
            itemCode: this.state.itemCode,
            subItemCode: this.state.subItemCode,
            damageCode: this.state.damageCode,
            damage: this.state.damage,
            severityCode: this.state.severityCode,
            severity: this.state.severity,
            actionCode: this.state.actionCode,
            action: this.state.action,
            locationCode: this.state.locationCode,
            pricePlanIds: this.state.pricePlanIds,
            repairLaborHours: this.state.repairLaborHours,
            repairLaborCost: parseFloat(this.state.repairLaborCost),
            paintLaborHours: this.state.paintLaborHours,
            paintLaborCost: parseFloat(this.state.paintLaborCost),
            partLaborHours: this.state.partLaborHours,
            partLaborCost: parseFloat(this.state.partLaborCost),
            partCost: parseFloat(this.state.partCost),
            finalPartCost: parseFloat(this.state.finalPartCost)
        }
    }

    fetchPricing = async (updatedDamage, name) => {
        let headers = csrf.getCSRFHeaders()
        headers['Content-Type'] = 'application/json';
        let isHours = false;
        if (name === 'paintLaborHours' || name === 'partLaborHours' || name === 'repairLaborHours') {
            isHours = true;
        }

        this.setState({isApiLoading: true});

        await Api._fetch(`${APPROVALS_API_URL}/unified/pricing`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({
                location: this.props.workOrderInfo.auctionCode,
                vin: this.props.unit?.vin,
                subSeries: getSubSeries(this.props.designatedDescription),
                chromeStyleId: getChromeStyleId(this.props.designatedDescription),
                groupCode: this.props.account.groupCode,
                accountNumber: this.props.consignment.manheimAccountNumber,
                categoryCode: this.props.consignment.categoryCode,
                consignmentHref: this.props.consignment.href,
                paintType: this.props.condition.paintType,
                damages: [updatedDamage]
            })
        })
            .then(response => {
                if (response.ok) {
                    return response.json()
                } else {
                    this.setStatusMessage("Error fetching pricing");
                    throw new Error("Error fetching pricing")
                }
            })
            .then(data => this.updateDamagePricing(data, isHours))
            .catch(e => {
                this.setState({isApiLoading: false});
                console.log(e);
                return e;
            });
        this.validatePricingInputs();
    };

    isCRSO = () => {
        return this.props.condition.conditionType?.toUpperCase() === CRSO;
    }

    // Verifying that at least one of the pricing inputs is greater than 0. 
    // If each case returns false (each input is either 0 or ''), we display the error message and disable the save button
    validatePricingInputs = () => {
        switch (true) {
            case !this.isCRSO() :
                this.setState({showPricingInputError: false})
                break;
            case parseFloat(this.state.repairLaborCost) > 0 :
                this.setState({showPricingInputError: false})
                break;
            case parseFloat(this.state.paintLaborCost) > 0 :
                this.setState({showPricingInputError: false})
                break;
            case parseFloat(this.state.partLaborCost) > 0 :
                this.setState({showPricingInputError: false})
                break;
            case parseFloat(this.state.partCost) > 0 :
                this.setState({showPricingInputError: false})
                break;
            case parseFloat(this.state.finalPartCost) > 0 :
                this.setState({showPricingInputError: false})
                break;
            default:
                this.setState({showPricingInputError: true})
                break;
        }
    }

    updateDamagePricing = async (pricingResponse, isHours) => {
        let responseDamage = pricingResponse[0];
        let partLaborCost = (Math.round(responseDamage.partLaborCost * 100) / 100).toFixed(2);
        let paintLaborCost = (Math.round(responseDamage.paintLaborCost * 100) / 100).toFixed(2);
        let repairLaborCost = (Math.round(responseDamage.repairLaborCost * 100) / 100).toFixed(2);
        let partCost = (Math.round(responseDamage.partCost * 100) / 100).toFixed(2);
        let finalPartCost = (Math.round(responseDamage.finalPartCost * 100) / 100).toFixed(2);
        if (isHours) {
            await this.setState({
                partLaborHours: responseDamage.partLaborHours,
                partLaborCost: partLaborCost,
                paintLaborHours: responseDamage.paintLaborHours,
                paintLaborCost: paintLaborCost,
                repairLaborHours: responseDamage.repairLaborHours,
                repairLaborCost: repairLaborCost,
                partDescription: responseDamage.partNumber,
                damageImageRequired: responseDamage.damageImageRequired || (partLaborCost + paintLaborCost + repairLaborCost >= 200.00)
            })
        } else {
            await this.setState({
                partLaborHours: responseDamage.partLaborHours,
                partLaborCost: partLaborCost,
                paintLaborHours: responseDamage.paintLaborHours,
                paintLaborCost: paintLaborCost,
                repairLaborHours: responseDamage.repairLaborHours,
                repairLaborCost: repairLaborCost,
                partCost: partCost,
                finalPartCost: finalPartCost,
                partDescription: responseDamage.partNumber,
                damageImageRequired: responseDamage.damageImageRequired || (partLaborCost + paintLaborCost + repairLaborCost + finalPartCost >= 200.00)
            });
        }
        this.setState({isApiLoading: false});
    };

    resetDamagePricing = () => {
        this.setState({
            repairLaborCost: '0.00',
            repairLaborHours: '0.00',
            paintLaborCost: '0.00',
            paintLaborHours: '0.00',
            partLaborCost: '0.00',
            partLaborHours: '0.00',
            partCost: '0.00',
            finalPartCost: '0.00'
        });
    }

    handlePricingUpdate = (damage) => {
        this.setState({
            pricePlanIds: damage.pricePlanIds,
            paintLaborHours: null,
            paintLaborCost: null,
            partLaborHours: null,
            partLaborCost: null,
            partCost: null,
            finalPartCost: null
        }, async () => {
            await this.fetchPricing(this.damageRequestObject(`${this.state.itemCode}-${this.state.subItemCode}-${this.state.damageCode}`));
        });
    };

    hidePricingOverlay = async () => {
        await this.setState({
            showPricing: false
        }, () => {
        })
    };

    cancelPricingOverlay = async () => {
        this.setState({
            repairLaborCost: '0.00',
            repairLaborHours: 0.0,
            paintLaborCost: '0.00',
            paintLaborHours: 0.0,
            partLaborCost: '0.00',
            partLaborHours: 0.0,
            partCost: '0.00',
            finalPartCost: '0.00',
            showPricing: false,
            pricePlanIds: []
        }, async () => {
            if (this.isPricingEmpty() && this.state.damage.actionCode !== "NA" && this.state.damage.actionCode !== "IC") {
                await this.setStatusMessage('Damage is missing pricing and needs to be corrected before saving');
            }
        })
    };

    requiredFieldsCompleted = () => {
        return (
            this.hasValidDamage() && (this.photoIsRequired()
                ? this.props.hasActiveImage(this.state.damageKey)
                : true)
        );
    };

    hasValidDamage = () => {
        return this.state.itemCode &&
            this.state.subItemCode &&
            this.state.damageCode &&
            this.state.severityCode &&
            this.state.chargeable
    }

    handleUpdatePricing = (event) => {
        let name = event.target.name;
        let originalDamage = this.damageRequestObject(`${this.state.itemCode}-${this.state.subItemCode}-${this.state.damageCode}`)
        if (name === 'repairLaborHours') {
            originalDamage.repairLaborCost = null;
            this.fetchPricing(originalDamage, name);
        }

        if (name === 'paintLaborHours') {
            originalDamage.paintLaborCost = null;
            this.fetchPricing(originalDamage, name);
        }

        if (name === 'partLaborHours') {
            originalDamage.partLaborCost = null;
            this.fetchPricing(originalDamage, name);
        }

        if (name === 'partCost') {
            originalDamage.finalPartCost = null;
            this.fetchPricing(originalDamage, name);
        }
    };

    render() {
        const isCRSO = this.isCRSO();

        const isStructural = this.props.isStructural;

        console.log("auto damage state", this.state);
        // account object will be null after clearing vehicle work order data from state, by clicking m logo from nativeapp.
        if (!this.props.account) {
            return null;
        }
        return (
            <div id="add-damage-content" className="bg-white">
                {this.state.isApiLoading && (
                    <SpinnerOverlay id='loading-spinner' showCancelButton={false} size={100}/>
                )}
                <Row className="mx-0">
                    <Col>
                        <Row>
                            <Col xs={12}>
                                <Row className="mx-0">
                                    {!isStructural && <Form.Group className="flex-grow-1">
                                        <Input.Label className="font-weight-bold">Item</Input.Label>
                                        <Input type="text" id="item-input" value={this.state.item} disabled/>
                                    </Form.Group>}
                                    {isStructural && <ItemDropdown onChange={this.handleItemChange}/>}
                                </Row>
                            </Col>
                            <Col xs={12}>
                                <Form.Group>
                                    <Input.Label className="font-weight-bold">Damage</Input.Label>
                                    <DamageDropdown onChange={this.handleDamageChange}
                                                    value={this.state.damageCode}
                                                    item={{
                                                        itemCode: this.state.itemCode,
                                                        subItemCode: this.state.subItemCode
                                                    }}
                                                    description={this.state.damage}
                                                    groupCode={this.props.account.groupCode}
                                                    categoryCode={this.props.consignment.categoryCode}
                                                    setStatusMessage={this.setStatusMessage}
                                                    disabled={!isStructural}
                                    />
                                    {!this.state.validation.damageIsValid &&
                                        <div className="mt-8 invalid-feedback" style={{display: 'block'}}><span><i
                                            className="icon prism-icon-notification-circle text-danger"/> Required</span>
                                        </div>}
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <Form.Group>
                                    <Input.Label className="font-weight-bold">Severity</Input.Label>
                                    <SeverityDropdown onChange={this.handleSeverityChange}
                                                      value={this.state.severityCode}
                                                      item={{
                                                          itemCode: this.state.itemCode,
                                                          subItemCode: this.state.subItemCode
                                                      }}
                                                      description={this.state.severity}
                                                      damageCode={this.state.damageCode}
                                                      groupCode={this.props.account.groupCode}
                                                      categoryCode={this.props.consignment.categoryCode}
                                                      setStatusMessage={this.setStatusMessage}
                                                      disabled={!isStructural}
                                    />
                                    {!this.state.validation.severityIsValid &&
                                        <div className="mt-8 invalid-feedback" style={{display: 'block'}}><span><i
                                            className="icon prism-icon-notification-circle text-danger"/> Required</span>
                                        </div>}
                                </Form.Group>
                            </Col>
                            <Col xs={12}>
                                <Form.Group>
                                    <Input.Label className="font-weight-bold">Chargeable</Input.Label>
                                    <ChargeableDropdown onChange={this.handleChargeableChange}
                                                        value={this.state.chargeable}
                                                        description={this.state.chargeableDescription}
                                                        item={{
                                                            itemCode: this.state.itemCode,
                                                            subItemCode: this.state.subItemCode
                                                        }}
                                                        damageCode={this.state.damageCode}
                                                        severityCode={this.state.severityCode}
                                                        groupCode={this.props.account.groupCode}
                                                        categoryCode={this.props.consignment.categoryCode}
                                                        setStatusMessage={this.setStatusMessage}
                                    />
                                    <div style={{display: "none"}}>
                                        <ActionDropdown onChange={this.handleActionChange}
                                                        value={this.state.actionCode}
                                                        item={{
                                                            itemCode: this.state.itemCode,
                                                            subItemCode: this.state.subItemCode
                                                        }}
                                                        damageCode={this.state.damageCode}
                                                        severityCode={this.state.severityCode}
                                                        groupCode={this.props.account.groupCode}
                                                        categoryCode={this.props.consignment.categoryCode}
                                                        auctionCode={this.props.workOrderInfo.auctionCode}
                                                        setStatusMessage={this.setStatusMessage}
                                                        clearAndValidatePricing={this.clearAndValidatePricingInputs}
                                                        disabled={true}

                                        /></div>
                                    {!this.state.validation.chargeableIsValid &&
                                        <div className="mt-8 invalid-feedback" style={{display: 'block'}}><span><i
                                            className="icon prism-icon-notification-circle text-danger"/> Required</span>
                                        </div>}
                                </Form.Group>
                            </Col>
                        </Row>

                        {this.state.showPricing &&
                            <SlidePanel isOpen={this.state.showPricing} width={"100vw"} zIndex={20000} showHeader={true}
                                        contentClassName="bg-white" from="right">
                                <Pricing hidePricingOverlay={this.hidePricingOverlay}
                                         id="pricing-overlay"
                                         damage={this.damageRequestObject(this.calculateDamageKey())}
                                         pricingTypes={this.state.pricingTypes}
                                         handlePricingUpdate={this.handlePricingUpdate}
                                         cancelPricingOverlay={this.cancelPricingOverlay}
                                />
                            </SlidePanel>}

                        {isCRSO &&

                            <DamagePricingInputs
                                data={this.state}
                                damage={this.props.damage}
                                toggle={this.props.toggle}
                                handleUpdatePricing={this.handleUpdatePricing}
                                priceChange={this.handlePriceChange}
                                formatCurrency={this.formatCurrency}
                                handleInputValidation={this.handleInputValidation}
                            />
                        }
                        {isCRSO &&
                            <Row>
                                <Col className="col-12 font-weight-bold">
                                    <TextCounter
                                        label="Part Number/Description"
                                        rows="1"
                                        id='part-description'
                                        onChange={this.handleEditPartDescriptionChange}
                                        value={this.state.partDescription}
                                        maxLength={30}
                                        disabled={this.state.disabled}
                                    />
                                </Col>
                            </Row>
                        }

                        <Row>
                            <Col className="col-12 font-weight-bold py-4">
                                <TextCounter
                                    id='damage-comments'
                                    onChange={this.handleNotesChange}
                                    placeholder="Enter Comments"
                                    label="Damage Comments"
                                    maxLength={30}
                                    rows="1"
                                    value={this.state.notes}
                                />
                            </Col>
                        </Row>
                        {/*</Col>*/}
                        {/*<Col xs={4} className="mt-4">*/}
                        <Row>
                            <Col xs={12}>
                                <OutlinePanel className="p-2 mb-4">
                                    <TakePhoto
                                        hasPhoto={this.props.hasActiveImage(
                                            this.state.damageKey
                                        )}
                                        photoIsRequired={this.photoIsRequired()}
                                        damage={this.state}
                                        damageKey={this.state.damageKey}
                                    />
                                </OutlinePanel>
                            </Col>
                        </Row>
                        {this.state.showPricingInputError ?
                            <Message id="missing-prices-message" color="danger"><i
                                className="icon prism-icon-notification-circle" color="danger"/>
                                {this.state.showEstimateError ? MISSING_ESTIMATE_MESSAGE : this.state.showPricingInputError ? MISSING_PRICING_MESSAGE : ''}
                            </Message>
                                : <></>}
                        <Row className="pb-4">
                            <Col xs={6} className="pr-2">
                                <Button id="auto-damage-cancel" className="w-100" outline
                                        onClick={this.props.onCancelClick}>Cancel</Button>
                            </Col>
                            <Col xs={6} className="pl-2">
                                <Button id="auto-damage-save"
                                        className="w-100"
                                        color="secondary"
                                        disabled={!this.requiredFieldsCompleted() || this.state.showPricingInputError || this.state.isApiLoading}
                                        onClick={this.handleSaveDamage}>Save
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        );
    }
}

const matchDispatchToProps = {
    addAutoDamage,
    hasActiveImage,
    showExteriorFlatCarView,
    addDamage
};

function mapStateToProps(
    {
        globalDisplay,
        account,
        consignment,
        workOrderInfo,
        damageImages,
        condition,
        designatedDescription,
        unit
    }
) {
    return {
        globalDisplay,
        account,
        consignment,
        workOrderInfo,
        damageImages,
        condition,
        designatedDescription,
        unit
    };
}

export default connect(mapStateToProps, matchDispatchToProps)(DamageModalDetails);