import React from "react";
import {
    clearIncompleteDamage,
    saveIncompleteDamage,
    setClearPanelOn,
    showCurrentFlatCarView,
    showDamageListView,
    showDamageSuccessMessage,
    showExteriorFlatCarView,
    showSuggestedDamagesPanel
} from "../../../actions/globalDisplayActions";
import {addAutoDamage, addDamage, deleteDamage, updateDamage} from "../../../actions/conditionActions";
import {
    deleteDamageImage,
    getActiveImage,
    getActiveImageObject,
    hasActiveImage,
    updateDamageImage
} from "../../../actions/damageImageActions";
import {AsyncTypeahead} from "react-bootstrap-typeahead";
import update from "immutability-helper";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import Col from "@prism/col";
import Row from "@prism/row";
import Form from "@prism/form";
import Input from "@prism/input";
import Button from "@prism/button";
import Message from "@prism/message";
import Api from "../../../components/Api";
import {connect} from "react-redux";
import OutlinePanel, {PanelHead} from "../../common/OutlinePanel";
import {ActionDropdown, DamageDropdown, SeverityDropdown,} from "./artComponents";
import TextCounter from "../../common/TextCounter";
import DamageSuccessMessage from "../../common/DamageSuccessMessage";
import {APPROVALS_API_URL, ENABLE_KEEP_DAMAGE_PHOTO} from "../../../config/env";
import FlatCarPanel from "./FlatCar/FlatCarPanel";
import MultimediaViewer from "@fyusion/prism-viewer";
import ChargeableDropdown from "./artComponents/ChargeableDropdown";
import {REQUIRED_PHOTO_ITEMS} from "../../../utils/requiredPhotoItems";
import TakePhoto from "./capturePhoto/TakePhoto";
import {addAnnouncementByCode, addAnnouncementForDamage} from "../../../actions/announcementsActions";
import ErrorBoundary from "../../common/ErrorBoundary";
import csrf from "../../../csrf";
import SlidePanel from "../../SlidePanel";
import DamageCalculator from "./DamageCalculator/DamageCalculator";
import Pricing from "../Pricing";
import {DamagePricingInputs} from "../../../utils/DamagePricingInputs";
import {
    CONDITION_ANNOUNCEMENT,
    CRSO,
    FYU_DAMAGE_ANALYSIS, MISSING_ESTIMATE_MESSAGE,
    MISSING_PRICING_MESSAGE,
    ODOMETER_INOP_ANNOUNCEMENT_CODE,
    ODOMETER_INOP_DAMAGE_KEY,
    ODOMETER_INOPERABLE,
    SMART_INSPECT,
    USE_EXISTING
} from "../../../utils/constants";
import {clearItemsList, clearSelectedFlatCarPanel} from "../../../actions/flatCarActions";
import {getChromeStyleId, getSubSeries, isDuplicateDamageKey} from "../../../utils/utils";
import {manuallyAddSuggestedDamage} from "../../../actions/SuggestedDamageActions";
import ConfirmationOrDecisionModal from "../../ConfirmationOrDecisionModal";
import SpinnerOverlay from "../../Spinner/SpinnerOverlay";
import {logObject} from "../../../actions/loggingActions";
import SuggestedDamages from "./SuggestedDamages";

const itemsSearchApi = (text) =>
    Api._fetch(
        `${APPROVALS_API_URL}/smartInspect/art/items/search?description=` +
        encodeURIComponent(text)
    );
const itemsSearchAPIDebounced = AwesomeDebouncePromise(itemsSearchApi, 300);
const defaultSlidePanelZindex = 4000;

class DamageEditView extends React.Component {
    state = {
        validation: {
            itemIsValid: true,
        },
        itemValidationMessage: "",
        text: "",
        options: [],
        itemSearchResults: [],
        showPricing: false,
        pricingTypes: [],
        isUpdate: false,
        isLoading: false,
        chargeable: null,
        damageKey: "",
        damageImageRequired: 'N',
        isDuplicateDamage: false,
        showCalculator: false,
        damageEstimatorQuestions: [],
        manufactureCode: null,
        directReturnCode: null,
        actionCodeFromArt: null,
        categoryCode: null,
        actionFromArt: null,
        turnInCalculatorFlag: false,
        showPricingInputError: false,
        showEstimateError: false,
        locationCode: "",
        isApiLoading: false,
        sourcePlatform: null,
        showKeepPhotoModal: false,
        keepPrevDamagePhoto: null,
        originalDamageImageUrl: null,
        photoQuestionAnswered: null,
        validationFailed: false,
        disabled: true
    };

    componentDidMount() {
        let damage = (this.props.globalDisplay.currentDamage && this.props.globalDisplay.currentDamage.item) ?
            this.props.globalDisplay.currentDamage : null;
        const isEdit = !!damage;
        if (!damage) {
            damage = (this.props.globalDisplay.incompleteDamage && this.props.globalDisplay.incompleteDamage.item) ?
                this.props.globalDisplay.incompleteDamage : null;
        }
        if (!!damage) {
            this.setState({
                ...damage,
                originalDamageKey: damage.damageKey,
                damageKeyAtWorkOrderLoad: damage.damageKeyAtWorkOrderLoad,
                text: damage.item,
                options: [damage.item],
                selectedItem: [damage.item],
                isUpdate: isEdit,
                hasImage: this.props.hasActiveImage(damage.damageKey),
                showImage: !!this.props.hasActiveImage(damage.damageKey),
                manufactureCode: this.props.consignment.manufactureCode,
                directReturnCode: this.props.damageRules.directReturnCode === 'Y' ? 'YES' : this.props.damageRules.directReturnCode,
                categoryCode: this.props.consignment.categoryCode,
                turnInCalculatorFlag: (this.props.damageRules.directReturnCode === 'Y' && this.props.consignment.manufactureCode === "FOR") || (this.props.consignment.categoryCode === "TRP" && this.props.consignment.manufactureCode === "TOY"),
                sourcePlatform: damage.sourcePlatform,
                isApiLoading: true,
                disabled: false
            }, async () => {
                let response = await this.fetchDamageEstimatorQuestions(true)
                this.setState({isApiLoading: false});

                if (response.questions) {
                    this.setState({
                        damageEstimatorQuestions: response,
                        isApiLoading: false
                    });
                } else {
                    this.setState({isApiLoading: false});
                }

                if (this.state.actionCode !== "NA" && this.state.actionCode !== "IC") {
                    this.validatePricingInputs();
                }
            });
        } else {
            this.setState({
                manufactureCode: this.props.consignment.manufactureCode,
                directReturnCode: this.props.damageRules.directReturnCode === 'Y' ? 'YES' : this.props.damageRules.directReturnCode,
                categoryCode: this.props.consignment.categoryCode,
                turnInCalculatorFlag: (this.props.damageRules.directReturnCode === 'Y' && this.props.consignment.manufactureCode === "FOR") || (this.props.consignment.categoryCode === "TRP" && this.props.consignment.manufactureCode === "TOY"),
                isApiLoading: false
            }, async () => {
            });

            this.scrollToTop();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if ((prevProps.damageImages !== this.props.damageImages) && this.props.hasActiveImage(this.state.damageKey)) {
            this.setState({
                showImage: true
            })
        }
    }

    handleItemChange = async (event) => {
        if (event.length) {
            const item = this.itemInSearchResults(event[0].label);
            if (item !== undefined) {
                if (this.state.isUpdate && this.shouldShowKeepPhotoModal()) {
                    this.setState({
                        showKeepPhotoModal: true,
                    }, async () => {
                    });
                }
                await this.setState({
                    itemCode: item.itemCode,
                    subItemCode: item.subItemCode,
                    locationCode: item.locationCode,
                    areaCode: item.areaCode,
                    description: item.description,
                    item: item.description
                }, async () => {
                });
                this.persistIncompleteDamage();
            }
        } else {
            if (event.length === 0) {
                this.setState({
                    options: []
                })
                this.wipeoutIncompleteDamage();
            }
            this.clearSelectedItem();
        }
    };

    shouldShowKeepPhotoModal = () => {
        const activeDamageImage = this.props.getActiveImageObject(this.state.originalDamageKey)
        return !!activeDamageImage
            && (!activeDamageImage.status || activeDamageImage.status === USE_EXISTING)
            && this.state.photoQuestionAnswered === null;
    }

    handleTextChange = async (text) => {
        const isValid = this.validateInput(text);
        if (!isValid) {
            this.clearSelectedItem();
        }
        await this.setState({text: text});

        const foundItem = this.itemInSearchResults(text);
        if (foundItem) {
            this.setSelectedItem(foundItem);
        } else if (isValid) {
            this.clearSelectedItem();
            const result = await this.fetchItemSearchResults(text.toLowerCase());
            if (!result.items) {

            } else if (result.items.length === 0) {
                this.setItemValidationMessageWithResults(
                    "Not a valid damage item. Please re-enter",
                    false,
                    result.items
                );
            } else {
                this.setItemValidationMessageWithResults("", true, result.items);
            }
        }
    };

    validateInput = (text) => {
        if (text.length < 3) {
            this.setItemValidationMessage(
                "Please enter at least 3 characters in the search",
                false,
                false
            );
            return false;
        } else {
            this.setItemValidationMessage("", true, true);
            return true;
        }
    };

    setItemValidationMessage = (message, isValid, isCharLimitExceed) => {
        let newState = update(this.state, {
            itemValidationMessage: {$set: message},
            validation: {$merge: {itemIsValid: isValid}},
        });
        if (isCharLimitExceed) {
            newState = update(newState, {
                $merge: {fetchInProgress: false, isLoading: false},
            });
        }
        this.setState(newState);
    };

    clearPageValues = () => {
        this._typeahead.clear();
        this.damageCommentsRef.handleChange({target: {value: ''}})
        if (this.isCRSO()) {
            this.partDescriptionRef.handleChange({target: {value: ''}})
        }
        this.setState({
            item: "",
            itemCode: "",
            subItemCode: "",
            damageCode: null,
            severityCode: null,
            actionCode: null,
            chargeable: null,
            damageKey: "",
            partDescription: null,
            itemSearchResults: [],
            disabled: true,
            selectedItem: null,
            text: "",
            options: [],
            notes: null,
            showImage: false,
            isDuplicateDamage: false,
            pricePlanIds: null,
            actionCodeFromArt: null,
            actionFromArt: null,
            showPricingInputError: false,
            damageImageRequired: 'N'
        });
        this.resetDamagePricing();
        this.wipeoutIncompleteDamage();
    };

    clearSelectedItem = () => {
        this.setState({
            item: "",
            itemCode: "",
            subItemCode: "",
            damageCode: "",
            severityCode: "",
            actionCode: "",
            chargeable: "",
            damageKey: "",
            itemSearchResults: [],
            disabled: true,
            selectedItem: null,
            isDuplicateDamage: false,
            actionCodeFromArt: "",
            actionFromArt: "",
        });
    };

    setSelectedItem = (item) => {
        this.setState({
            ...item,
            damageCode: "",
            severityCode: "",
            actionCode: "",
            chargeable: "",
            disabled: true,
            selectedItem: null,
            isDuplicateDamage: false,
            actionCodeFromArt: "",
            actionFromArt: "",
        }, () => this.setState({damageKey: this.calculateDamageKey()}));
    };

    setSelectedItemFromFlatCar = (item) => {
        this.setState({
            ...item,
            item: item.description,
            text: item.description,
            options: [item.description],
            selectedItem: [item.description],
            damageCode: "",
            severityCode: "",
            actionCode: "",
            chargeable: "",
            disabled: true,
            isDuplicateDamage: false,
            actionCodeFromArt: "",
            actionFromArt: "",
        }, () => this.setState({damageKey: this.calculateDamageKey()}));
    };

    itemInSearchResults = (text) => {
        return this.state.itemSearchResults.find(
            (item) => item.description === text
        );
    };

    showItemList = () => {
        const hasValidItems =
            this.state.text.length > 2 && this.state.options.length;
        const itemHasBeenSelected = this.state.itemCode !== "";
        return !!hasValidItems && !itemHasBeenSelected;
    };

    requiredFieldsCompleted = () => {
        return (
            this.state.itemCode &&
            this.state.subItemCode &&
            this.state.damageCode &&
            this.state.severityCode &&
            this.state.chargeable &&
            (this.photoIsRequired() ? (this.props.hasActiveImage(this.state.damageKey) || this.state.keepPrevDamagePhoto) : true)
        );
    };

    saveButtonDisabled = () => {
        return !this.requiredFieldsCompleted() || this.state.isDuplicateDamage || this.state.showPricingInputError
            || this.state.isLoading || this.state.isApiLoading || this.state.validationFailed;
    };

    handleDamageChange = async ({target: {options, selectedIndex, value}}) => {
        if (this.state.isUpdate && this.shouldShowKeepPhotoModal()) {
            this.setState({
                showKeepPhotoModal: true,
            }, async () => {
            });
        }
        await this.setState({
            damageCode: value,
            damage: options[selectedIndex].text,
            actionCode: null,
            action: null,
            actionCodeFromArt: null,
            actionFromArt: null,
            severityCode: null,
            severity: null,
            chargeableDescription: null,
            chargeable: null,
            statusMessage: {text: "", color: ""},
            disabled: true,
            isDuplicateDamage: this.isDuplicateDamage(value),
            showImage: this.isDuplicateDamage(value) ? false : this.state.showImage,
            suggestedDamageExist: this.isSuggestedDamageAvailable(value)
        }, () => this.setState({damageKey: this.calculateDamageKey()}));
        this.persistIncompleteDamage();
    };

    handleSeverityChange = async ({target: {options, selectedIndex, value}}) => {
        await this.setState({
            severityCode: value,
            severity: options[selectedIndex].text,
            chargeableDescription: null,
            chargeable: null,
            actionCode: null,
            action: null,
            statusMessage: {text: "", color: ""},
            disabled: true,
            turnInCalculatorFlag: this.isFordOrToyota()
        }, () => this.setState({damageKey: this.calculateDamageKey()}));
        this.persistIncompleteDamage();
    };

    handleShowEstimatorAndPricingUpdates = async (value) => {
        if (value === '' || value === 'NA' || value === 'IC') {
            this.resetDamagePricing();
            this.setState({
                showPricingInputError: false
            })
        } else {
            let callPricing;
            try {
                callPricing = await this.handleShowPricing();
            } catch (e) {
                return;
            }

            if (!await this.handleShowDamageEstimator() && callPricing) {
                await this.fetchPricing(this.damageRequestObject(`${this.state.itemCode}-${this.state.subItemCode}-${this.state.damageCode}`));
            }
        }
    }

    updateAction = ({target: {options, selectedIndex, value}}) => {
        console.log("handle updateAction", value);
        if (this.isCRSO()) {
            if (value && this.state.actionCode !== value) {
                this.setState({
                    actionCode: value,
                    action: options[selectedIndex].text,
                    statusMessage: {text: '', color: ''},
                    disabled: false,
                    damageEstimatorQuestions: [],
                    showCalculator: false
                }, async () => {
                    await this.handleShowEstimatorAndPricingUpdates(value);
                });
            } else if (this.state.actionCodeFromArt !== this.state.actionCode) {
                console.log("Updating ActionCode from ART api", this.state.actionCodeFromArt, this.state.actionFromArt);
                this.setState({
                    actionCode: this.state.actionCodeFromArt,
                    action: this.state.actionFromArt,
                    statusMessage: {text: '', color: ''},
                    disabled: false,
                    damageEstimatorQuestions: [],
                    showCalculator: false
                }, async () => {
                    await this.handleShowEstimatorAndPricingUpdates(this.state.actionCodeFromArt);
                });
            }
        }
    };

    handleActionChange = async ({target: {options, selectedIndex, value}}) => {
        if (this.isCRSO() && this.state.turnInCalculatorFlag) {
            await this.setState({
                actionCodeFromArt: value,
                actionFromArt: options[selectedIndex].text,
            }, async () => {
                if (!await this.handleShowDamageEstimator()) {
                    console.log("Fall back to ART api action value", value)
                    await this.setState({
                        actionCode: value,
                        action: options[selectedIndex].text,
                        statusMessage: {text: '', color: ''},
                        disabled: false,
                        damageEstimatorQuestions: [],
                        turnInCalculatorFlag: false
                    }, async () => {
                        await this.handleShowEstimatorAndPricingUpdates(value);
                    });
                    this.persistIncompleteDamage();
                }
            });
            this.persistIncompleteDamage();
            return;
        }
        if (this.isCRSO() && this.state.actionCode !== value) {
            this.setState({
                actionCode: value,
                action: options[selectedIndex].text,
                statusMessage: {text: '', color: ''},
                disabled: false,
                damageEstimatorQuestions: [],
                showPricingInputError: false
            }, async () => {
                await this.handleShowEstimatorAndPricingUpdates(value);
            });

        } else {
            this.setState({
                actionCode: value,
                action: options[selectedIndex].text,
                statusMessage: {text: "", color: ""},
                disabled: false,
                damageEstimatorQuestions: [],
                showPricingInputError: false
            }, () => this.setState({damageKey: this.calculateDamageKey()}));
        }
    };

    handleShowDamageEstimator = async () => {
        this.setState({
            damageEstimatorQuestions: [],
            isApiLoading: true
        });
        const response = await this.fetchDamageEstimatorQuestions();
        if (response.questions && this.isPricingEmpty()) {
            this.setState({
                showCalculator: true,
                damageEstimatorQuestions: response,
                isApiLoading: false
            });
            return true;
        } else if (response.status === 404) {
            this.setState({
                showCalculator: false,
                turnInCalculatorFlag: false,
                isApiLoading: false
            });
            return false;
        } else {
            this.setState({
                showCalculator: false,
                turnInCalculatorFlag: false,
                isApiLoading: false
            });
            this.setErrorMessage("Error calling the estimator, try again later")
            return false;
        }
    };

    fetchDamageEstimatorQuestions = (onEdit = false) => {
        let headers = csrf.getCSRFHeaders();
        headers['Content-Type'] = 'application/json';
        if (!onEdit && this.isFord() && this.state.turnInCalculatorFlag) {
            return Api.getDamageEstimatorQuestions({
                itemCode: this.state.itemCode,
                subItemCode: this.state.subItemCode,
                damageCode: this.state.damageCode,
                severityCode: this.state.severityCode,
                manufactureCode: this.state.manufactureCode,
                directTurnIn: this.state.directReturnCode,
                headers: headers
            });
        } else if (!onEdit && this.isToyota() && this.state.turnInCalculatorFlag) {
            return Api.getDamageEstimatorQuestions({
                itemCode: this.state.itemCode,
                subItemCode: this.state.subItemCode,
                damageCode: this.state.damageCode,
                severityCode: this.state.severityCode,
                manufactureCode: this.state.manufactureCode,
                categoryCode: this.state.categoryCode,
                headers: headers
            });
        }
        return Api.getDamageEstimatorQuestions({
            itemCode: this.state.itemCode,
            subItemCode: this.state.subItemCode,
            damageCode: this.state.damageCode,
            severityCode: this.state.severityCode,
            actionCode: this.state.actionCode,
            chargeable: this.state.chargeable,
            headers: headers
        });
    }

    updatePricingForDamage = async ({repairLaborCost, repairLaborHours, aluminum}) => {
        await this.updatePricingFromDamageEstimator({repairLaborCost, repairLaborHours, aluminum});
        await this.fetchPricing(this.damageRequestObject(`${this.state.itemCode}-${this.state.subItemCode}-${this.state.damageCode}`));
    };

    updateTurnInCalculatorFlag = () => {
        this.setState({turnInCalculatorFlag: false}, () => console.debug("finished resetting updateTurnInCalculatorFlag "))
    };

    updatePricingFromDamageEstimator = async ({repairLaborCost, repairLaborHours, aluminum}) => {
        repairLaborCost && this.setState({repairLaborCost: repairLaborCost, showCalculator: false});
        repairLaborHours && this.setState({
            repairLaborHours: repairLaborHours,
            repairLaborCost: null,
            showCalculator: false
        });
        this.setState({aluminum: aluminum, showCalculator: false});
    };

    isRentalReturnTypeEstimator = (estimatorType) => {
        return (estimatorType && (estimatorType.toLowerCase().startsWith('fordguide') || estimatorType.toLowerCase().startsWith('toyotaguide')));
    }

    isFordOrToyota = () => {
        return ((this.state.directReturnCode === 'YES' && this.state.manufactureCode === "FOR") || (this.state.categoryCode === "TRP" && this.state.manufactureCode === "TOY"));
    }

    isFord = () => {
        return ((this.state.directReturnCode === 'YES' && this.state.manufactureCode === "FOR"));
    }

    isToyota = () => {
        return (this.state.categoryCode === "TRP" && this.state.manufactureCode === "TOY");
    }

    hideDamageCalculator = () => {

        if (this.state.damageEstimatorQuestions && this.isRentalReturnTypeEstimator(this.state.damageEstimatorQuestions.estimatorType)) {
            this.setState({showCalculator: false, severityCode: null},
                () => {
                    if (this.state.fetchPricingOnCancel) {
                        this.fetchPricing(this.damageRequestObject(`${this.state.itemCode}-${this.state.subItemCode}-${this.state.damageCode}`));
                    }
                });
        } else {
            this.setState({showCalculator: false},
                () => {
                    if (this.state.fetchPricingOnCancel) {
                        this.fetchPricing(this.damageRequestObject(`${this.state.itemCode}-${this.state.subItemCode}-${this.state.damageCode}`));
                    }
                });
        }
    }

    showDamageCalculator = () => {
        this.setState({showCalculator: true})
    };

    resetDamagePricing = () => {
        console.debug("resetting damage pricing");
        this.setState({
            damageImageRequired: 'N',
            repairLaborCost: '0.00',
            repairLaborHours: '0.00',
            paintLaborCost: '0.00',
            paintLaborHours: '0.00',
            partLaborCost: '0.00',
            partLaborHours: '0.00',
            partCost: '0.00',
            finalPartCost: '0.00'
        }, () => console.debug("finished resetting damage pricing"));
    }

    handleShowPricing = () => {
        this.setState({
            repairLaborHours: null,
            repairLaborCost: null,
            paintLaborHours: null,
            paintLaborCost: null,
            partLaborHours: null,
            partLaborCost: null,
            partCost: null,
            finalPartCost: null,
            pricePlanIds: null,
            isApiLoading: true
        });

        let headers = csrf.getCSRFHeaders();
        headers['Content-Type'] = 'application/json';
        let request = {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({
                location: this.props.workOrderInfo.auctionCode,
                vin: this.props.unit?.vin,
                subSeries: getSubSeries(this.props.designatedDescription),
                chromeStyleId: getChromeStyleId(this.props.designatedDescription),
                groupCode: this.props.account.groupCode,
                accountNumber: this.props.consignment.manheimAccountNumber,
                categoryCode: this.props.consignment.categoryCode,
                consignmentHref: this.props.consignment.href,
                paintType: this.props.condition.paintType,
                damages: [this.state]
            })
        };

        return Api.fetchEstimate(request).then((data) => {
            const {model, mitchell, account} = data;
            const pricingTypes = [];
            let showPricing = false;
            let overlay = [];
            if (Array.isArray(account)) {
                if (account.length === 1) {
                    overlay.push('account');
                    this.updateDamageWithPricing('account', account);
                } else if (account.length > 1) {
                    let m = new Map();
                    m.set('Account', account);
                    pricingTypes.push(m)
                }
            }
            if (Array.isArray(model)) {
                if (model.length === 1) {
                    overlay.push('model');
                    this.updateDamageWithPricing('model', model);
                } else if (model.length > 1) {
                    let m = new Map();
                    m.set('Model', model);
                    pricingTypes.push(m)
                }
            }
            if (Array.isArray(mitchell)) {
                if (mitchell.length === 1) {
                    overlay.push('mitchell');
                    this.updateDamageWithPricing('mitchell', mitchell);
                } else if (mitchell.length > 1) {
                    let m = new Map();
                    m.set('Mitchell', mitchell);
                    pricingTypes.push(m)
                }
            }

            let callPricing;
            let fetchPricingOnCancel = true;

            if (pricingTypes.length === 0 && overlay.length === 0) {
                this.resetDamagePricing();
                this.validatePricingInputs();
                fetchPricingOnCancel = false;
                callPricing = false;
            }

            if (pricingTypes.length !== 0) {
                showPricing = true;
                callPricing = false;
            }

            if (overlay.length !== 0 && pricingTypes.length === 0) {
                callPricing = true;
            }

            this.setState({
                pricingTypes,
                showPricing: showPricing,
                fetchPricingOnCancel: fetchPricingOnCancel
            });
            this.setState({isApiLoading: false});

            return callPricing;
        })
            .catch(() => {
                this.setState({isApiLoading: false});
                this.clearAndValidatePricingInputs();
                throw new Error("Error fetching pricing");
            });
    };

    clearAndValidatePricingInputs = () => {
        this.resetDamagePricing();
        this.validatePricingInputs();
        this.setState({
            disabled: false,
            showEstimateError: true
        });
    }

    updateDamageWithPricing = (name, value) => {
        let pricePlanList = this.state.pricePlanId;
        const pricePlanId = {
            pricingType: name,
            pricePlanId: value[0].id
        };

        if (pricePlanList === undefined) {
            pricePlanList = [];
            pricePlanList.push(pricePlanId);
        } else {
            pricePlanList.push(pricePlanId);
        }

        this.setState({
            pricePlanIds: pricePlanList
        })
    };

    damageRequestObject = (damageKey) => {
        return {
            damageKey,
            item: this.state.item,
            itemCode: this.state.itemCode,
            subItemCode: this.state.subItemCode,
            damageCode: this.state.damageCode,
            damage: this.state.damage,
            severityCode: this.state.severityCode,
            severity: this.state.severity,
            actionCode: this.state.actionCode,
            action: this.state.action,
            locationCode: this.state.locationCode,
            pricePlanIds: this.state.pricePlanIds,
            repairLaborHours: this.state.repairLaborHours,
            repairLaborCost: parseFloat(this.state.repairLaborCost),
            paintLaborHours: this.state.paintLaborHours,
            paintLaborCost: parseFloat(this.state.paintLaborCost),
            partLaborHours: this.state.partLaborHours,
            partLaborCost: parseFloat(this.state.partLaborCost),
            partCost: parseFloat(this.state.partCost),
            finalPartCost: parseFloat(this.state.finalPartCost)
        }
    }

    fetchPricing = async (updatedDamage, name) => {
        let headers = csrf.getCSRFHeaders()
        headers['Content-Type'] = 'application/json';
        let isHours = false;
        if (name === 'paintLaborHours' || name === 'partLaborHours' || name === 'repairLaborHours') {
            isHours = true;
        }

        this.setState({isApiLoading: true});

        await Api._fetch(`${APPROVALS_API_URL}/unified/pricing`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({
                location: this.props.workOrderInfo.auctionCode,
                vin: this.props.unit?.vin,
                subSeries: getSubSeries(this.props.designatedDescription),
                chromeStyleId: getChromeStyleId(this.props.designatedDescription),
                groupCode: this.props.account.groupCode,
                accountNumber: this.props.consignment.manheimAccountNumber,
                categoryCode: this.props.consignment.categoryCode,
                consignmentHref: this.props.consignment.href,
                paintType: this.props.condition.paintType,
                damages: [updatedDamage]
            })
        })
            .then(response => {
                this.setState({isApiLoading: false});
                if (response.ok) {
                    return response.json()
                } else {
                    this.setStatusMessage("Error fetching pricing");
                    throw new Error("Error fetching pricing")
                }
            })
            .then(data => this.updateDamagePricing(data, isHours))
            .catch(e => {
                this.setState({isApiLoading: false});
                console.log(e);
                return e;
            });
        this.validatePricingInputs();
        console.log("state after pricing", this.state)
    };

    updateDamagePricing = async (pricingResponse, isHours) => {
        this.props.logObject('DamageEditView', 'updateDamagePricing', 'pricingResponse', pricingResponse)
        let responseDamage = pricingResponse[0];
        let partLaborCost = (Math.round(responseDamage.partLaborCost * 100) / 100).toFixed(2);
        let paintLaborCost = (Math.round(responseDamage.paintLaborCost * 100) / 100).toFixed(2);
        let repairLaborCost = (Math.round(responseDamage.repairLaborCost * 100) / 100).toFixed(2);
        let partCost = (Math.round(responseDamage.partCost * 100) / 100).toFixed(2);
        let finalPartCost = (Math.round(responseDamage.finalPartCost * 100) / 100).toFixed(2);
        let isCostAtLeast200 = (Number(partLaborCost) + Number(paintLaborCost) + Number(repairLaborCost) + Number(finalPartCost) >= 200.00);
        if (isHours) {
            await this.setState({
                partLaborHours: responseDamage.partLaborHours,
                partLaborCost: partLaborCost,
                paintLaborHours: responseDamage.paintLaborHours,
                paintLaborCost: paintLaborCost,
                repairLaborHours: responseDamage.repairLaborHours,
                repairLaborCost: repairLaborCost,
                partDescription: responseDamage.partNumber,
                damageImageRequired: (isCostAtLeast200 || responseDamage.damageImageRequired === 'Y') ? 'Y' : 'N'
            }, () => this.props.logObject('DamageEditView', 'updateDamagePricing-isHours', 'state', this.state))

        } else {
            await this.setState({
                partLaborHours: responseDamage.partLaborHours,
                partLaborCost: partLaborCost,
                paintLaborHours: responseDamage.paintLaborHours,
                paintLaborCost: paintLaborCost,
                repairLaborHours: responseDamage.repairLaborHours,
                repairLaborCost: repairLaborCost,
                partCost: partCost,
                finalPartCost: finalPartCost,
                partDescription: responseDamage.partNumber,
                damageImageRequired: (isCostAtLeast200 || responseDamage.damageImageRequired === 'Y') ? 'Y' : 'N'
            }, () => this.props.logObject('DamageEditView', 'updateDamagePricing-isNotHours', 'state', this.state));
        }

        this.persistIncompleteDamage();
    };

    handleManualUpdatePricing = (event) => {
        let name = event.target.name;

        if (name === 'repairLaborCost') {
            this.formatCurrency(event);
            this.updateManualDamagePricing();
            this.validatePricingInputs();
        }

        if (name === 'paintLaborCost') {
            this.formatCurrency(event);
            this.updateManualDamagePricing();
            this.validatePricingInputs();
        }

        if (name === 'partLaborCost') {
            this.formatCurrency(event);
            this.updateManualDamagePricing();
            this.validatePricingInputs()
        }

        if (name === 'finalPartCost') {
            this.formatCurrency(event);
            this.updateManualDamagePricing();
            this.validatePricingInputs();
        }
    }

    updateManualDamagePricing = () => {
        let partLaborCost = (Math.round(this.state.partLaborCost * 100) / 100).toFixed(2);
        let paintLaborCost = (Math.round(this.state.paintLaborCost * 100) / 100).toFixed(2);
        let repairLaborCost = (Math.round(this.state.repairLaborCost * 100) / 100).toFixed(2);
        let finalPartCost = (Math.round(this.state.finalPartCost * 100) / 100).toFixed(2);
        let isCostAtLeast200 = (Number(partLaborCost) + Number(paintLaborCost) + Number(repairLaborCost) + Number(finalPartCost) >= 200.00);
        this.setState({
            partLaborCost: partLaborCost,
            paintLaborCost: paintLaborCost,
            repairLaborCost: repairLaborCost,
            finalPartCost: finalPartCost,
            damageImageRequired: (isCostAtLeast200 || this.state.damageImageRequired === 'Y') ? 'Y' : 'N'
        }, () => this.props.logObject('DamageEditView', 'updateDamagePricing-isNotHours', 'state', this.state));

        this.persistIncompleteDamage();
    }

    handleChargeableChange = async ({target: {options, selectedIndex, value}}) => {
        await this.setState({
            chargeable: value,
            chargeableDescription: options[selectedIndex].text,
        });
        this.persistIncompleteDamage();
    };

    handleNotesChange = async (event) => {
        await this.setState({
            notes: event.value,
        });
        this.persistIncompleteDamage();
    };

    handleEditPartDescriptionChange = async (event) => {
        await this.setState({
            partDescription: event.value,
        });
        this.persistIncompleteDamage();
    };

    calculateDamageKey = () => {
        return `L1-${this.state.itemCode}-${this.state.subItemCode}-${this.state.damageCode}`;
    };

    fetchItemSearchResults = async (text) => {
        this.setState({isLoading: true});
        const result = await itemsSearchAPIDebounced(text);

        if (result.ok) {
            this.setState({isLoading: false});
            return result.json();
        } else {
            this.setState({isLoading: false});
            this.setStatusMessage(
                "Error retrieving Item list please try again later"
            );
            return {items: []};
        }
    };

    setItemValidationMessageWithResults = (message, isValid, results) => {
        let newState = update(this.state, {
            itemValidationMessage: {$set: message},
            validation: {$merge: {itemIsValid: isValid}},
        });

        let itemList = [];
        results.forEach(function (entry) {
            const item = {id: entry.code, label: entry.description};
            itemList.push(item);
        });

        newState = update(newState, {
            $merge: {
                itemSearchResults: results,
                options: itemList,
                isLoading: false,
            },
        });

        this.setState(newState);
    };

    setStatusMessage = (message) => {
        console.log("ERR MSG", message);
    };

    // IMPORTANT: this needs to be called AFTER React state has been updated!!
    persistIncompleteDamage = () => {
        if (!this.state.isUpdate) {
            const damage = this.buildDamageObject();
            damage.damageEstimatorQuestions = this.state.damageEstimatorQuestions
            this.props.saveIncompleteDamage(damage);
        }
    }

    // IMPORTANT: this needs to be called AFTER React state has been updated!!
    wipeoutIncompleteDamage = () => {
        if (!this.state.isUpdate) {
            this.props.clearIncompleteDamage();
        }
    }

    handleSaveDamage = async () => {
        const damage = this.buildDamageObject();
        if (this.state.isUpdate) {
            let newDamageKey = `L1-${damage.itemCode}-${damage.subItemCode}-${damage.damageCode}`
            // TODO Spoke with Caroline. There is a card in the backlog to handle other manually-added auto damages too.
            if (newDamageKey === ODOMETER_INOP_DAMAGE_KEY) {
                this.props.addAutoDamage(damage);
                this.props.addAnnouncementByCode(CONDITION_ANNOUNCEMENT, ODOMETER_INOP_ANNOUNCEMENT_CODE, ODOMETER_INOPERABLE);
            } else {
                this.props.updateDamage(damage, this.props.condition, this.state.originalDamageKey);
                this.props.addAnnouncementForDamage(damage);
                if (ENABLE_KEEP_DAMAGE_PHOTO === 'true') {
                    this.handleDamageImage(damage, newDamageKey);
                }
            }
            this.props.showDamageListView();
        } else {
            // TODO Spoke with Caroline. There is a card in the backlog to handle other manually-added auto damages too.
            if (damage.damageKey === ODOMETER_INOP_DAMAGE_KEY) {
                this.props.addAutoDamage(damage);
                this.props.addAnnouncementByCode(CONDITION_ANNOUNCEMENT, ODOMETER_INOP_ANNOUNCEMENT_CODE, ODOMETER_INOPERABLE);
            } else {
                if (!this.state.isDuplicateDamage && this.state.suggestedDamageExist) {
                    this.props.suggestedDamages?.damages.every(suggestedDamage => {
                        if (damage.damageKey === suggestedDamage.artCodes.damageKey) {
                            this.props.manuallyAddSuggestedDamage(suggestedDamage.artCodes.damageKey);
                            return false;
                        }
                        return true;
                    });
                }
                this.props.addDamage(damage);
                this.props.addAnnouncementForDamage(damage);
                this.props.showDamageSuccessMessage();
            }
            await this.clearPageValues();
            this.scrollToTop();
            this.props.showCurrentFlatCarView(this.props.globalDisplay.flatCarView);
        }

        if (this.props.globalDisplay.clearSelectedPanel) {
            this.flatCarRef && this.flatCarRef.clearAllSelections();
            if (!this.panelSuggestedDamages()) {
                this.props.clearSelectedFlatCarPanel();
            }
        } else {
            this.props.showDamageListView();
            this.props.showSuggestedDamagesPanel();
            this.props.setClearPanelOn();
        }
    };

    handleDamageImage(damage, newDamageKey) {
        const hasActiveImage = this.props.hasActiveImage(damage.damageKey);

        // keeping OR not keeping but took new photo
        if (this.state.keepPrevDamagePhoto || (this.state.keepPrevDamagePhoto === false && hasActiveImage)) {
            // originalDamageKey is the damageKey at the time the damage was opened for editing
            // damageKeyAtWorkOrderLoad is the damageKey when the WO was opened...CYA for editing damage multiple times
            // damageKeyAtWorkOrderLoad is needed for knowing which image to mark DELETED for removing from the image set in the processor
            this.props.updateDamageImage(newDamageKey, this.state.originalDamageImageUrl, {
                keepPrevPhoto: this.state.keepPrevDamagePhoto,
                damageKeyAtWorkOrderLoad: this.state.damageKeyAtWorkOrderLoad,
                originalDamageKey: this.state.originalDamageKey
            })
            // not keeping and did not take new photo
        } else if (this.state.keepPrevDamagePhoto === false) {
            this.props.deleteDamageImage(this.state.originalDamageKey)
        }
    }

    handleCancelClick = () => {
        this.scrollToTop();
        this.props.showSuggestedDamagesPanel();
        if (this.props.suggestedDamages) {
            this.props.clearItemsList();
        } else if (!this.flatCarRef) {
            this.props.clearItemsList();
            this.props.clearSelectedFlatCarPanel();
        } else {
            this.flatCarRef.clearAllSelections();
        }
        this.props.showDamageListView();
        this.props.setClearPanelOn();
        this.clearPageValues();
    };

    buildDamageObject = () => {
        return {
            item: this.state.item,
            itemCode: this.state.itemCode,
            subItemCode: this.state.subItemCode,
            damage: this.state.damage,
            damageCode: this.state.damageCode,
            severity: this.state.severity,
            severityCode: this.state.severityCode,
            action: this.state.action,
            actionCode: this.state.actionCode,
            locationCode: this.state.locationCode,
            damageKey: (this.state.isUpdate ? this.state.originalDamageKey : this.state.damageKey),
            notes: this.state.notes,
            chargeable: this.state.chargeable,
            chargeableDescription: this.state.chargeableDescription,
            damageKeyAtWorkOrderLoad: this.state.damageKeyAtWorkOrderLoad,
            hasImage: this.props.hasActiveImage(this.state.damageKey),
            repairLaborHours: parseFloat(this.state.repairLaborHours),
            repairLaborCost: parseFloat(this.state.repairLaborCost),
            paintLaborHours: parseFloat(this.state.paintLaborHours),
            paintLaborCost: parseFloat(this.state.paintLaborCost),
            partLaborHours: parseFloat(this.state.partLaborHours),
            partLaborCost: parseFloat(this.state.partLaborCost),
            partCost: parseFloat(this.state.partCost),
            finalPartCost: parseFloat(this.state.finalPartCost),
            partDescription: this.state.partDescription,
            aluminum: this.state.aluminum,
            sourcePlatform: this.getSourcePlatform(),
            damageImageRequired: this.state.damageImageRequired,
            pricePlanIds: this.state.pricePlanIds
        };
    };

    getSourcePlatform() {
        return this.state.sourcePlatform === FYU_DAMAGE_ANALYSIS ? FYU_DAMAGE_ANALYSIS : SMART_INSPECT;
    }

    toggleFlatCar = () => {
        this.setState({
            showImage: !this.state.showImage
        });
    };

    photoIsRequired = () => {
        const longDamageKey = `${this.state.itemCode}-${this.state.subItemCode}-${this.state.damageCode}-${this.state.severityCode}`;
        if (this.isCRSO()) {
            return (this.state.damageImageRequired === 'Y' ||
                REQUIRED_PHOTO_ITEMS.includes(longDamageKey.split("-").slice(0, 4).join("-")))
        }
        // CRNE
        return REQUIRED_PHOTO_ITEMS.includes(
            longDamageKey.split("-").slice(0, 4).join("-")
        );
    };

    scrollToTop = () => {
        window.scrollTo(0, 0);
    };

    isDuplicateDamage = (damageCode) => {
        const newlyEnteredDamageKey = `L1-${this.state.itemCode}-${this.state.subItemCode}-${damageCode}`
        if (!this.props.condition.damages) {
            return false;
        } else if (this.state.isUpdate && newlyEnteredDamageKey === this.props.globalDisplay.currentDamage?.damageKey) {
            return false;
        } else {
            return !!this.props.condition.damages.find(damage => isDuplicateDamageKey(damage.damageKey, newlyEnteredDamageKey))
        }
    }

    isSuggestedDamageAvailable = (damageCode) => {
        const newlyEnteredDamageKey = `L1-${this.state.itemCode}-${this.state.subItemCode}-${damageCode}`
        if (this.props.globalDisplay.currentDamage === null || newlyEnteredDamageKey === this.props.globalDisplay.currentDamage.damageKey) {
            return false;
        }
        return !!this.props.suggestedDamages?.damages.find(damage => damage.artCodes.damageKey.startsWith(newlyEnteredDamageKey))
    }

    isCRSO = () => {
        return this.props.condition.conditionType?.toUpperCase() === CRSO;
    }

    // Verifying that at least one of the pricing inputs is greater than 0.
    // If each case returns false (each input is either 0 or ''), we display the error message and disable the save button
    validatePricingInputs = () => {
        switch (true) {
            case !this.isCRSO() :
                this.resetPricingErrorFlags();
                break;
            case parseFloat(this.state.repairLaborCost) > 0 :
                this.resetPricingErrorFlags();
                break;
            case parseFloat(this.state.paintLaborCost) > 0 :
                this.resetPricingErrorFlags();
                break;
            case parseFloat(this.state.partLaborCost) > 0 :
                this.resetPricingErrorFlags();
                break;
            case parseFloat(this.state.partCost) > 0 :
                this.resetPricingErrorFlags();
                break;
            case parseFloat(this.state.finalPartCost) > 0 :
                this.resetPricingErrorFlags();
                break;
            default:
                this.setState({showPricingInputError: true})
                break;
        }
    }

    resetPricingErrorFlags() {
        this.setState({
            showPricingInputError: false,
            showEstimateError: false
        })
    }

    handlePricingUpdate = async (damage) => {
        await this.setState({
            pricePlanIds: damage.pricePlanIds,
            paintLaborHours: null,
            paintLaborCost: null,
            partLaborHours: null,
            partLaborCost: null,
            partCost: null,
            finalPartCost: null
        }, async () => {
            await this.fetchPricing(this.damageRequestObject(`${this.state.itemCode}-${this.state.subItemCode}-${this.state.damageCode}`));
        });
        this.persistIncompleteDamage();
    };

    hidePricingOverlay = async () => {
        await this.setState({
            showPricing: false
        }, () => {
        })
    };

    handleUpdatePricing = (event) => {
        let name = event.target.name;
        let originalDamage = this.damageRequestObject(`${this.state.itemCode}-${this.state.subItemCode}-${this.state.damageCode}`)
        if (name === 'repairLaborHours') {
            originalDamage.repairLaborCost = null;
            this.fetchPricing(originalDamage, name);
        }

        if (name === 'paintLaborHours') {
            originalDamage.paintLaborCost = null;
            this.fetchPricing(originalDamage, name);
        }

        if (name === 'partLaborHours') {
            originalDamage.partLaborCost = null;
            this.fetchPricing(originalDamage, name);
        }

        if (name === 'partCost') {
            originalDamage.finalPartCost = null;
            this.fetchPricing(originalDamage, name);
        }
    };

    handlePriceChange = async (event) => {
        await this.updateInputState(event)
        this.validatePricingInputs()
    };

    updateInputState = (event) => {
        let value = parseFloat(event?.target.value);
        if (isNaN(value)) {
            value = '';
        } else {
            value = Math.round(value * 100) / 100;
        }
        return new Promise(async (resolve) => {
            await this.setState({[event.currentTarget.name]: value}, resolve)
            this.persistIncompleteDamage();
        })
    }

    handleInputValidation = (dataFromChild) => {
        this.setState(dataFromChild);
    }

    formatCurrency = (event) => {
        if (isNaN(event.target.value) || event.target.value === '') {
            this.setState({
                [event.target.name]: '0.00'
            });
        } else {
            this.setState({
                [event.target.name]: parseFloat(event.target.value).toFixed(2)
            });
        }
    };

    isPricingEmpty = () => {
        return ((!this.state.repairLaborCost || this.state.repairLaborCost <= 0 || this.state.repairLaborCost === '') &&
            (!this.state.paintLaborCost || this.state.paintLaborCost <= 0 || this.state.paintLaborCost === '') &&
            (!this.state.partLaborCost || this.state.partLaborCost <= 0 || this.state.partLaborCost === '') &&
            (!this.state.partCost || this.state.partCost <= 0 || this.state.partCost === '') &&
            (!this.state.finalPartCost || this.state.finalPartCost <= 0 || this.state.finalPartCost === ''));
    };

    cancelPricingOverlay = async () => {
        this.setState({
            repairLaborCost: '0.00',
            repairLaborHours: 0.0,
            paintLaborCost: '0.00',
            paintLaborHours: 0.0,
            partLaborCost: '0.00',
            partLaborHours: 0.0,
            partCost: '0.00',
            finalPartCost: '0.00',
            showPricing: false,
            pricePlanIds: []
        }, async () => {
            if (this.isPricingEmpty() && this.state.damage.actionCode !== "NA" && this.state.damage.actionCode !== "IC") {
                await this.setStatusMessage(MISSING_PRICING_MESSAGE);
            }
            this.validatePricingInputs();
        })
    };

    resetTransform = () => {
        if (this.flatCarRef) {
            this.flatCarRef.resetTransform();
        }
    }

    keepExistingPhoto = () => {
        const imageUrl = this.props.getActiveImage(this.state.originalDamageKey)
        this.setState({
            showKeepPhotoModal: false,
            photoQuestionAnswered: true,
            keepPrevDamagePhoto: true,
            showImage: true,
            originalDamageImageUrl: imageUrl
        })
    }

    doNotKeepExistingPhoto = () => {
        this.setState({
            showKeepPhotoModal: false,
            photoQuestionAnswered: true,
            keepPrevDamagePhoto: false,
            showImage: false,
            originalDamageImageUrl: null
        })
    }

    getDamageImage = () => {
        if (this.state.keepPrevDamagePhoto && !!this.state.originalDamageImageUrl) {
            return this.state.originalDamageImageUrl
        } else {
            return this.props.getActiveImage(this.state.damageKey)
        }
    }

    hasActivePhoto = () => {
        let damageKey = this.state.keepPrevDamagePhoto ? this.state.originalDamageKey : this.state.damageKey;
        return this.props.hasActiveImage(damageKey);
    }

    isDuplicateSuggestedDamage = (suggestedDamage) => {
        return !!this.props.condition.damages?.find(damage => damage.damageKey === suggestedDamage.artCodes.damageKey);
    }

    activeDamageOnPanel = (damage) => {
        return damage.artCodes.panelId === this.props.globalDisplay.selectedFlatCarPanel && !damage.action;
    }

    panelSuggestedDamages = () => {
        return (!!this.props.suggestedDamages && this.props.globalDisplay.selectedFlatCarPanel != null) ?
            this.props.suggestedDamages?.damages.find(damage => (this.activeDamageOnPanel(damage) && !this.isDuplicateSuggestedDamage(damage))) : null;
    }

    setApiLoading = (value) => {
        this.setState({isApiLoading: value});
    }

    render() {
        const isCRSO = this.isCRSO()
        return (
            <>
                {this.state.isApiLoading && (
                    <SpinnerOverlay id='loading-spinner' showCancelButton={false} size={100}/>
                )}
                <DamageSuccessMessage/>
                {(this.state.showKeepPhotoModal && ENABLE_KEEP_DAMAGE_PHOTO === 'true') && <ConfirmationOrDecisionModal
                    shouldShowModal={true}
                    modalHeader="Keep Photo?"
                    modalBody="Do you want to keep the existing damage photo?"
                    modalType="keep-photo"
                    confirmationText="Yes"
                    cancelText="No"
                    handleClose={this.doNotKeepExistingPhoto}
                    handleContinue={this.keepExistingPhoto}
                />}
                {(this.hasActivePhoto()) &&
                this.state.showImage ? (
                    <ErrorBoundary>
                        <MultimediaViewer
                            id="damage-image-content"
                            hideAddOns={true}
                            key={this.props.globalDisplay.damageImageChecksum}
                            data={[
                                {
                                    category: "DMG",
                                    src: {
                                        thumbnail: "",
                                        lowRes: this.getDamageImage(),
                                    },
                                    description: this.state.item,
                                    damageKey: this.state.damageKey,
                                },
                            ]}
                        />
                    </ErrorBoundary>
                ) : (
                    <FlatCarPanel setSelectedItem={this.setSelectedItemFromFlatCar}
                                  ref={(ref) => this.flatCarRef = ref}
                                  isApiLoading={this.state.isApiLoading}
                                  hideItemSearch={(this.props.globalDisplay.displaySuggestedDamages && !!this.panelSuggestedDamages())}
                    />
                )}
                {this.state.showCalculator &&
                    <SlidePanel isOpen={this.state.showCalculator} width={"100vw"} showHeader={true}
                                contentClassName="bg-white" from="left" zIndex={defaultSlidePanelZindex}>
                        <DamageCalculator hideDamageCalculator={this.hideDamageCalculator}
                                          id="damage-calculator"
                                          type={this.state.damageEstimatorQuestions.estimatorType}
                                          manufactureCode={this.state.manufactureCode}
                                          directReturnCode={this.state.directReturnCode}
                                          categoryCode={this.state.categoryCode}
                                          questions={this.state.damageEstimatorQuestions.questions}
                                          damage={this.damageRequestObject()}
                                          updatePricingForDamage={this.updatePricingForDamage}
                                          updateTurnInCalculatorFlag={this.updateTurnInCalculatorFlag}
                                          updateAction={this.updateAction}
                                          updateChargable={this.handleChargeableChange}
                        />
                    </SlidePanel>}
                {this.state.showPricing && <SlidePanel isOpen={this.state.showPricing} width={"100vw"} showHeader={true}
                                                       contentClassName="bg-white" from="right"
                                                       zIndex={defaultSlidePanelZindex + 1}>
                    <Pricing hidePricingOverlay={this.hidePricingOverlay}
                             id="pricing-overlay"
                             damage={this.damageRequestObject()}
                             pricingTypes={this.state.pricingTypes}
                             handlePricingUpdate={this.handlePricingUpdate}
                             cancelPricingOverlay={this.cancelPricingOverlay}
                    />
                </SlidePanel>}
                {(this.props.globalDisplay.displaySuggestedDamages && !!this.panelSuggestedDamages()) ?
                    <SuggestedDamages setApiLoading={this.setApiLoading}/>
                    : <OutlinePanel id="add-damage-content" className="bg-white m-4">
                        <PanelHead className="panel-head-add-damage text-right">
                            {this.props.hasActiveImage(this.state.damageKey) &&
                                this.state.showImage && (
                                    <Button
                                        color="secondary"
                                        onClick={this.toggleFlatCar}
                                        id="back-to-flatcar-button"
                                    >
                                        Back to Flat Car
                                    </Button>
                                )}
                        </PanelHead>
                        {this.state.isDuplicateDamage &&
                            <Message id="duplicate-damage-message" color="danger">
                                This damage combination already exists.
                            </Message>
                        }
                        <Col xs={12}>
                            <Row>
                                <Col xs={12} className="px-0">
                                    <Form.Group className="flex-grow-1">
                                        <Input.Label className="font-weight-bold">
                                            Item
                                        </Input.Label>
                                        <AsyncTypeahead
                                            ref={(ref) => this._typeahead = ref}
                                            isLoading={this.state.isLoading}
                                            emptyLabel={null}
                                            name="add-damage-item"
                                            id="add-damage-item-list"
                                            placeholder="Search All Damage Items"
                                            onSearch={this.handleTextChange}
                                            onChange={this.handleItemChange}
                                            options={this.state.options}
                                            iconRight="prism-icon-search icon"
                                            autoComplete="off"
                                            useCache={false}
                                            selected={this.state.selectedItem && this.state.selectedItem}
                                            open={this.showItemList()}
                                            defaultOpen={false}
                                            style={{border: '1px solid #babcbe'}}
                                        />
                                    </Form.Group>
                                    {!this.state.validation.itemIsValid && (
                                        <Row className="col-12" style={{marginTop: "-16px"}}>
                                            <div
                                                className="invalid-feedback"
                                                id="item-search-validation-message"
                                                style={{display: "block"}}
                                            >
                        <span>
                          <i className="icon prism-icon-notification-circle text-danger"/>{" "}
                            {this.state.itemValidationMessage}
                        </span>
                                            </div>
                                        </Row>
                                    )}
                                    <Form.Group>
                                        <Input.Label className="font-weight-bold">
                                            Damage
                                        </Input.Label>
                                        <DamageDropdown
                                            onChange={this.handleDamageChange}
                                            value={this.state.damageCode}
                                            item={{
                                                itemCode: this.state.itemCode,
                                                subItemCode: this.state.subItemCode,
                                            }}
                                            groupCode={this.props.account.groupCode}
                                            categoryCode={this.props.consignment.categoryCode}
                                            setStatusMessage={this.setStatusMessage}
                                        />
                                    </Form.Group>
                                    <Form.Group>
                                        <Input.Label className="font-weight-bold">
                                            Severity
                                        </Input.Label>
                                        <SeverityDropdown
                                            onChange={this.handleSeverityChange}
                                            value={this.state.severityCode}
                                            item={{
                                                itemCode: this.state.itemCode,
                                                subItemCode: this.state.subItemCode,
                                            }}
                                            damageCode={this.state.damageCode}
                                            groupCode={this.props.account.groupCode}
                                            categoryCode={this.props.consignment.categoryCode}
                                            setStatusMessage={this.setStatusMessage}
                                        />
                                    </Form.Group>
                                    <Form.Group>
                                        <Input.Label className="font-weight-bold">
                                            Chargeable
                                        </Input.Label>
                                        <ChargeableDropdown
                                            onChange={this.handleChargeableChange}
                                            value={this.state.chargeable}
                                            description={this.state.chargeableDescription}
                                            item={{
                                                itemCode: this.state.itemCode,
                                                subItemCode: this.state.subItemCode,
                                            }}
                                            damageCode={this.state.damageCode}
                                            severityCode={this.state.severityCode}
                                            groupCode={this.props.account.groupCode}
                                            categoryCode={this.props.consignment.categoryCode}
                                            setStatusMessage={this.setStatusMessage}
                                        />
                                        <div style={{display: "none"}}>
                                            <ActionDropdown
                                                onChange={this.handleActionChange}
                                                value={this.state.actionCode}
                                                item={{
                                                    itemCode: this.state.itemCode,
                                                    subItemCode: this.state.subItemCode,
                                                }}
                                                damageCode={this.state.damageCode}
                                                severityCode={this.state.severityCode}
                                                groupCode={this.props.account.groupCode}
                                                categoryCode={this.props.consignment.categoryCode}
                                                auctionCode={this.props.workOrderInfo.auctionCode}
                                                setStatusMessage={this.setStatusMessage}
                                                clearAndValidatePricing={this.clearAndValidatePricingInputs}
                                                disabled={true}
                                            />
                                        </div>
                                    </Form.Group>
                                </Col>
                            </Row>
                            {isCRSO &&
                                <DamagePricingInputs
                                    data={this.state}
                                    damage={this.props.damage}
                                    toggle={this.props.toggle}
                                    handleUpdatePricing={this.handleUpdatePricing}
                                    handleManualUpdatePricing={this.handleManualUpdatePricing}
                                    priceChange={this.handlePriceChange}
                                    formatCurrency={this.formatCurrency}
                                    handleInputValidation={this.handleInputValidation}/>}
                            {isCRSO &&
                                <Row>
                                    <Col className="font-weight-bold px-0 flex-grow-1">
                                        <TextCounter
                                            ref={(ref) => this.partDescriptionRef = ref}
                                            label="Part Number/Description"
                                            rows="1"
                                            id='part-description'
                                            onChange={this.handleEditPartDescriptionChange}
                                            value={this.state.partDescription}
                                            maxLength={30}
                                            disabled={this.state.disabled}
                                        />
                                    </Col>
                                    <Col className="pt-4 pr-0 flex-grow-0">
                                        {this.state.damageEstimatorQuestions.questions &&
                                            <Button outline
                                                    id='damage-calculator-button'
                                                    onClick={this.showDamageCalculator}>
                                                <i className="icon prism-icon-file-spreadsheet"/>
                                            </Button>}
                                    </Col>
                                </Row>

                            }
                            <Row className="mt-3">
                                <Col className="col-12 font-weight-bold px-0">
                                    <TextCounter
                                        ref={(ref) => this.damageCommentsRef = ref}
                                        id="damage-comments"
                                        onChange={this.handleNotesChange}
                                        placeholder="Enter Comments"
                                        label="Damage Comments"
                                        maxLength={30}
                                        rows="1"
                                        value={this.state.notes}
                                    />
                                </Col>
                            </Row>
                            <Row className={"mx-0 mt-4"}>
                                {this.hasActivePhoto() &&
                                !this.state.showImage ?
                                    (
                                        <Button
                                            className="w-100"
                                            color="secondary"
                                            onClick={this.toggleFlatCar}
                                            id="show-damage-photo-button"
                                            disabled={this.state.isDuplicateDamage}
                                        >
                                            Show Photo
                                        </Button>
                                    )
                                    :
                                    this.state.keepPrevDamagePhoto !== true ?
                                        (
                                            <TakePhoto
                                                hasPhoto={this.props.hasActiveImage(this.state.damageKey) || this.state.keepPrevDamagePhoto}
                                                photoIsRequired={this.photoIsRequired()}
                                                damage={this.state}
                                                damageKey={this.state.damageKey}
                                                isDuplicateDamage={this.state.isDuplicateDamage}
                                            />
                                        ) :
                                        <></>
                                }
                            </Row>
                            {this.state.showPricingInputError ?
                                <Message id="missing-prices-message" color="danger"><i
                                    className="icon prism-icon-notification-circle" color="danger"/>
                                    {this.state.showEstimateError ? MISSING_ESTIMATE_MESSAGE : this.state.showPricingInputError ? MISSING_PRICING_MESSAGE : ''}
                                </Message> : <></>}
                            <Row className={"my-4"}>
                                <Col xs={6}>
                                    <Button
                                        className="w-100"
                                        outline
                                        onClick={this.handleCancelClick}
                                    >
                                        Cancel
                                    </Button>
                                </Col>
                                <Col xs={6}>
                                    <Button
                                        className="w-100"
                                        color="secondary"
                                        disabled={this.saveButtonDisabled()}
                                        onClick={this.handleSaveDamage}
                                    >
                                        Save
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </OutlinePanel>}
            </>
        );
    }
}

const matchDispatchToProps = {
    showDamageListView,
    addDamage,
    updateDamage,
    getActiveImage,
    getActiveImageObject,
    hasActiveImage,
    updateDamageImage,
    deleteDamageImage,
    addAnnouncementForDamage,
    addAnnouncementByCode,
    addAutoDamage,
    deleteDamage,
    showExteriorFlatCarView,
    showCurrentFlatCarView,
    clearItemsList,
    clearSelectedFlatCarPanel,
    manuallyAddSuggestedDamage,
    showDamageSuccessMessage,
    logObject,
    showSuggestedDamagesPanel,
    saveIncompleteDamage,
    clearIncompleteDamage,
    setClearPanelOn
};

function mapStateToProps(
    {
        globalDisplay,
        account,
        consignment,
        workOrderInfo,
        damageImages,
        condition,
        damageRules,
        designatedDescription,
        designatedDescriptionResponse,
        unit,
        suggestedDamages,
        validationFailed
    }
) {
    return {
        globalDisplay,
        account,
        consignment,
        workOrderInfo,
        damageImages,
        condition,
        damageRules,
        designatedDescription,
        designatedDescriptionResponse,
        unit,
        suggestedDamages,
        validationFailed
    };
}

export default connect(mapStateToProps, matchDispatchToProps, null, {forwardRef: true})(DamageEditView);